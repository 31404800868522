import React from 'react';
import banner from '@/assets/images/about/banner.jpg';
import title1 from '@/assets/images/about/title1.png';
import titleNew1 from '@/assets/images/about/titleNew1.png';
import title2 from '@/assets/images/about/title2.png';
import title3 from '@/assets/images/about/title3.png';
import authorization1 from '@/assets/images/about/authorization1.png';
import authorization2 from '@/assets/images/about/authorization2.png';
import authorization3 from '@/assets/images/about/authorization3.png';
import authorization4 from '@/assets/images/about/authorization4.png';
import IconNew1 from '@/assets/images/about/iconNew1.png';
import IconNew2 from '@/assets/images/about/iconNew2.png';
import IconNew3 from '@/assets/images/about/iconNew3.png';
import IconNew4 from '@/assets/images/about/iconNew4.png';
import desc from '@/assets/images/about/desc.png';
import { enterpriseCulture}  from '@/pages/const';
import styles from './index.module.less';

const About = () => (
    <div>
        <div className={styles.about}>
            <div className={styles.banner}>
                <img src={banner} alt="" />
                <div className={`${styles.banner_title} animateTopFade`}>
                    <h4>客户第一、诚信正直   高效协作、追求结果</h4>
                </div>
            </div>
            
            <div className={styles.panel1}>
                <div className={styles.content}>
                    <h4 className={styles.title}>关于分效</h4>
                    <img src={title1} alt="" alt='关于分效' />
                    <div className={styles.desc}>
                        <p>上海分效科技是一家专注于物联网广告服务的科技公司，通过“IOT+SAAS”改造传统屛媒，基于开放平台连接广告主和媒体主两端，利用大数据平台为广告主提供精准高效的线下广告投放服务，同时运用多种营销解决方案帮助客户提升销售收入。</p>
                        <p>分效科技和国内上市公司达成战略合作，成为了广电授权的独家运营商，深度服务全国各地广电进行智能化升级改造，实现一键智能化播控投放，打通线下户外广告和线上新媒体广告链路，为公共出行广告注入数字化新活力。</p>
                    </div>
                    <div className={styles.descImg}>
                        <img src={desc} alt="" />
                    </div>
                </div>
            </div>

            <div className={styles.panel2}>
                <div className={styles.content}>
                    <h4 className={styles.title}>企业文化</h4>
                    <img src={title2} alt="" />
                    <div className={styles.enterpriseCulture}>
                        {
                            enterpriseCulture.map(item => (
                                <div>
                                    <img src={item.icon} alt="" />
                                    <h4>{item.title}</h4>
                                    <p>{item.desc}</p>
                                    <p>{item.desc1}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className={[styles.content,styles.content1].join(' ')}>
                    <h4 className={styles.title}>三年规划（2023-2025）</h4>
                    <img src={titleNew1} alt="" />
                    <div className={styles.newFlexBox}>
                        <div className={styles.boxContent}>
                            <div className={styles.titleBox}>
                                <img src={IconNew1} alt="" />
                                <span>巨大的广告价值</span>
                            </div>
                            <div className={styles.contentBox}>
                                <div>
                                    <span style={{marginRight:'12px'}} >·</span>
                                    <span className={styles.fontBox}>高覆盖渗透，极具粘性：</span>
                                    结合支付宝商业化变现能力，覆盖50城+，15万+辆车辆，30万+终端，年累计流量池200亿人次，支付宝出行线上数据加持，结合线上线下精准营销 
                                </div>
                                <div style={{marginTop:20}}>
                                    <span style={{marginRight:'12px'}} >·</span>
                                    <span >公交出行从公交到户外</span>
                                    <span className={styles.fontBox}>电视联动运营流量转化</span>
                                    5%：高频观看使用，高启动次数和留存时长，覆盖不同人群用户群，实现流量+内容变现
                                </div>
                            </div>
                        </div>
                        <div className={styles.boxContent}>
                            <div className={styles.titleBox}>
                                <img src={IconNew2} alt="" />
                                <span>广阔的变现潜力</span>
                            </div>
                            <div className={styles.contentBox}>
                                <div>
                                    <span style={{marginRight:'12px'}} >·</span>
                                    <span className={styles.fontBox}>内容平台承接变现载体：</span>
                                    获取用户兴趣爱好，将内容转换作为变现前提
                                </div>
                                <div>
                                    <span style={{marginRight:'12px'}} >·</span>
                                    <span className={styles.fontBox}>用户关注好的内容：</span>
                                    被吸引消费，从而实现私域运营及本地生活到店引流
                                </div>
                                <div>
                                    <span style={{marginRight:'12px'}} >·</span>
                                    <span className={styles.fontBox}>互联网大厂变现合作：</span>
                                    结合线上互联网公司的流量优势，实现端到端线上线下一张网矩阵式营销合作
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className={styles.newFlexBox}>
                        <div className={styles.boxContent}>
                            <div className={styles.titleBox}>
                                <img src={IconNew3} alt="" />
                                <span>数据精准丰富</span>
                            </div>
                            <div className={styles.contentBox}>
                                <div>
                                    <span style={{marginRight:'12px'}} >·</span>
                                    <span >通过社交和行为数据采集丰富的</span>
                                    <span className={styles.fontBox}>兴趣爱好数据</span>
                                    <span >，强大数据收集和分析能力，不断从数据中</span>
                                    <span className={styles.fontBox}>抽取商业价值</span>。
                                </div>
                                <div style={{marginTop:20}}>
                                    <span style={{marginRight:'12px'}} >·</span>
                                    <span >终端定位GPS及LBS技术特点运用，实现基于热门商圈竞价排名，</span>
                                    <span className={styles.fontBox}>提高广告溢价空间</span>
                                </div>
                            </div>
                        </div>
                        <div className={styles.boxContent}>
                            <div className={styles.titleBox}>
                                <img src={IconNew4} alt="" />
                                <span>数据输出</span>
                            </div>
                            <div className={styles.contentBox}>
                                <div>
                                    <span style={{marginRight:'12px'}} >·</span>
                                    <span className={styles.fontBox}>全国性出行数据库</span>
                                    <span >，为政府提供数字化服务，解决社会稳定安全性</span>
                                </div>
                                <div style={{marginTop:20}}>
                                    <span style={{marginRight:'12px'}} >·</span>
                                    <span >数据的精准丰富，基于用户的精准广告投放，通过</span>
                                    <span className={styles.fontBox}>数据变现</span>
                                    帮助企业主实现品效为导向性的推广及增大流量入口。
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.panel3}>
                <div className={styles.container}>
                    <div className={styles.content}>
                        <h4 className={styles.title}>资质授权</h4>
                        <img src={title3} alt="" />
                    </div>
                    <div className={styles.authorization}>
                        <img src={authorization1} alt="" />
                        <img src={authorization2} alt="" />
                        <img src={authorization3} alt="" />
                        <img src={authorization4} alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default About;
