import React, { useEffect, useState } from 'react';
import qrCode from '@/assets/images/qrCode.png';
import { withRouter } from 'react-router-dom';
import styles from './index.module.less';

const Footer = (props) => {
    const [footer, setFooter] = useState(true);
    useEffect(() => {
        if(props.location.pathname == '/join_add'){
            setFooter(false)
        } else {
            setFooter(true)
        }
      }, [props.location.pathname])
    return (
    <div className={styles.footer} style={{ display: footer ? 'block' : 'none' }}>
        <div className={styles.footer_content}>
            <div className={styles.leftPlate}>
                <p className={styles.title}>联系我们</p>
                <p className={styles.desc}>招商热线：17705812754</p>
                <p className={styles.desc}>联系地址：上海市徐汇区田林路130号20号楼</p>
                <p className={styles.desc}>联系邮箱：fx@fenxiaotech.com</p>
                <p className={styles.desc}>杭州总部地址：杭州市西湖区西溪首座A1-1-617</p>
            </div>
            <div className={styles.rightPlate}>
                <div className={styles.rightPlate_img}>
                    <img src={qrCode} alt=""  />
                </div>
            </div>
        </div>
        <div className={styles.copyRight}>
            <p className={styles.copyRight_content}>
                杭州分效科技有限公司 CopyRight@2024
                <a style={{ color: '#ffffff' }} target= '_blank' href="https://beian.miit.gov.cn/?spm=a2cmq.17630005.0.0.42d979feE8056P#/Integrated/index”">浙ICP备2024096404号-1</a> 
            </p>
        </div>
    </div>
    )
};

export default withRouter(Footer);
