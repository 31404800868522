import React, { useState } from 'react';
import banner from '@/assets/images/medium/bannerMobile.png';
import title1 from '@/assets/images/medium/title1.png';
import img1 from '@/assets/images/medium/img1.png';
import title2 from '@/assets/images/medium/title2.png';
import title3 from '@/assets/images/medium/title3.png';
import title4 from '@/assets/images/medium/title4.png';
import newTitle1 from '@/assets/images/medium/newTitle1.png';
import newTitle2 from '@/assets/images/medium/newTitle2.png';
import newTitle3 from '@/assets/images/medium/newTitle3.png';
import newIcon1 from '@/assets/images/medium/newIcon1.png';
import newIcon2 from '@/assets/images/medium/mobileIcon1.png';
import newIcon3 from '@/assets/images/medium/mobileIcon2.png';
import newIcon4 from '@/assets/images/medium/mobileIcon3.png';
import newIcon5 from '@/assets/images/medium/mobileIcon4.png';
import newIcon6 from '@/assets/images/medium/newIcon6.png';
import newIcon7 from '@/assets/images/medium/newIcon7.png';
import newIcon8 from '@/assets/images/medium/newIcon8.png';
import newIcon10 from '@/assets/images/medium/newIcon10.png';
import newIcon11 from '@/assets/images/medium/newIcon11.png';
import newIcon12 from '@/assets/images/medium/newIcon12.png';
import newIcon13 from '@/assets/images/medium/newIcon13.png';
import mobilePng1 from '@/assets/images/medium/mobilePng1.png';
import mobilePng2 from '@/assets/images/medium/mobilePng2.png';
import mobileAliPay1 from '@/assets/images/medium/mobileAliPay1.png';
import mobileAliPay2 from '@/assets/images/medium/mobileAliPay2.png';
import mobileAliPay3 from '@/assets/images/medium/mobileAliPay3.png';
import mobileAliPay4 from '@/assets/images/medium/mobileAliPay4.png';
import newCar from '@/assets/images/medium/newCar.png';

import { advantageList, diverseContentList, programVideoList, mediaCharacteristic } from '@/pages/const';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectCoverflow, Navigation, Autoplay, Controller } from 'swiper';
import 'swiper/less'
import 'swiper/less/navigation'
import 'swiper/less/pagination'
import styles from './index.module.less';
import videoPlay from '@/assets/images/medium/video.png';
import { Row, Col } from 'antd'

SwiperCore.use([Controller]);

const Medium = () => {
    const [showControls, setShowControls] = useState(false);


    function playVideo(e) {
        const myVideo = e.target.parentNode.previousSibling;
        if (myVideo) {
            if (myVideo.pause) {
                myVideo.play();
            } else {
                myVideo.pause();
            }
        }
        setShowControls(true)
    }

    const newFlexBoxList1 = [
        {
            img: newIcon2,
            line1: '综合化营销场景',
            line2: '即时落地',
        },
        {
            img: newIcon3,
            line1: '优势结合差异化',
            line2: '广告投放',
        },
    ]
    const newFlexBoxList2 = [
        {
            img: newIcon4,
            line1: '目标受众人群',
            line2: '更有针对性',
        },
        {
            img: newIcon5,
            line1: '使投放数据全链路',
            line2: '数字化',
        },
    ]

    const newImgList = [
        {
            img: newIcon10,
            title: '公交'
        },
        {
            img: newIcon11,
            title: '定点感应触发投放'
        },
        {
            img: newIcon12,
            title: '巴士特惠/品牌派样'
        },
        {
            img: newIcon13,
            title: '电子围栏覆盖投放'
        },
    ]

    const alipayFlexList = [
        {
            img: newIcon6,
            title: '定向能力'
        }, {
            img: newIcon7,
            title: '人群包定制'
        }, {
            img: newIcon8,
            title: '优化能力'
        },
    ]
    const aliPayList = [mobileAliPay1, mobileAliPay2, mobileAliPay3, mobileAliPay4]
    const roundFlexList=[
        {
            title:'有屏车辆',
            number:'10W',
            content:'运营车辆20万台',
            color:'#fff',
            bgc:'#2187FF'
        },
        {
            title:'屏幕数量',
            number:'15W',
            content:'3直辖市、20省',
            color:'#2187FF',
            bgc:'#E3F0FF'
        },
    ]
    const roundFlexList2=[
        {
            title:'覆盖客流',
            number:'2729W',
            content:'日均客流5354W',
            color:'#2187FF',
            bgc:'#E3F0FF'
        },
        {
            title:'覆盖人口',
            number:'10%',
            content:'常住人口2.7亿',
            color:'#fff',
            bgc:'#2187FF'
        },
    ]
    return (
        <div className={styles.medium}>
            <div className={styles.banner}>
                <img src={banner} alt="" />
                <div className={`${styles.banner_title} animateTopFade`}>
                    <h4>线上+线下<br />全媒体资源触达</h4>
                </div>
            </div>

            <div className={styles.newTopBox}>
                <div>
                    分效公交媒体 <br /> 传统占领心智投放逻辑
                </div>
                <img src={newIcon1} alt="" />
                <div>
                    支付宝出行 <br /> 成熟互联网线上玩法
                </div>
            </div>
            <div className={styles.newBgcBox}>
                <div className={styles.flexBox}>
                    {
                        newFlexBoxList1.map(ele => {
                            return <div className={styles.flexThing}>
                                <img src={ele.img} alt="" />
                                <div className={styles.line}>{ele.line1}</div>
                                <div>{ele.line2}</div>
                            </div>
                        })
                    }
                </div>
                <div className={styles.flexBox}>
                    {
                        newFlexBoxList2.map(ele => {
                            return <div className={styles.flexThing}>
                                <img src={ele.img} alt="" />
                                <div className={styles.line}>{ele.line1}</div>
                                <div>{ele.line2}</div>
                            </div>
                        })
                    }
                </div>
                <div className={styles.titleNewBox}>
                    <div>广告智能化内容</div>
                    <img src={newTitle1} alt="" />

                </div>
                <div className={styles.contentBox}>
                    通过多样化技术手段实现不同的智能广告投放：<br /> 1、LBS技术应用实现公交智能报站，结合客户所在地点覆盖可到店精准人群，解决线下门店引流及私域运营问题。<br /> 2、结合自主研发线上商城，进行巴士定制产品直接扫码购买及巴士空投派样活动，拓展即看即买新形式，高峰值3小时有效用户派样5600单，高于线下派样媒体效率100倍。<br />
                    3、线上直播热点+最新公交技术实现实时联动，帮助品牌直接售卖转化。
                </div>
                <Row align="middle" gutter={[16, 16]} className={styles.imgFlexBox}>
                    {
                        newImgList.map(ele => {
                            return <Col span={12}>
                                <div>
                                    <img src={ele.img} alt="" />
                                    <div style={{ marginTop: 8 }}>{ele.title}</div>
                                </div>
                            </Col>
                        })
                    }

                </Row>



            </div>
            <Row className={styles.imgFlexBox} style={{ marginTop: 16 }} gutter={16}>
                <Col span={12}>
                    <div>
                        <img src={mobilePng1} alt="" />
                        <div style={{ marginTop: 8 }}>定制直播-案例1</div>
                    </div>

                </Col>
                <Col span={12}>
                    <div>
                        <img src={mobilePng2} alt="" />
                        <div style={{ marginTop: 8 }}>定制直播-案例2</div>
                    </div>

                </Col>
            </Row>
            <div className={styles.titleNewBox}>
                <div>支付宝灯火计划合作</div>
                <img src={newTitle2} alt="" />
            </div>
            <div className={styles.contentBox}>
                1、支付宝拥有10亿+用户、丰富的商业场景。<br /> 2、用户打开支付宝是为了获取服务，支付宝为商家的商业化提供展示广告、效果广告、信息流广告、互动广告、推荐广告等各类广告形式。<br /> 3、提供多种人群定向投放标签，支持平台自动化及自动化投放。<br /> 4、提供发券、小程序、生活号等组合营销方案，实施多维数据追踪和效果分析。
            </div>
            <div className={styles.alipayFlexBox}>
                {
                    alipayFlexList.map(ele => {
                        return <div className={styles.thing}>
                            <img src={ele.img} alt="" />
                            <div>{ele.title}</div>
                        </div>
                    })
                }

            </div>
            <Row className={styles.aliPayImgBox} gutter={[18, 16]}>
                {
                    aliPayList.map(ele => {
                        return <Col span={12}>
                            <div>
                                <img src={ele} alt="" />
                            </div>
                        </Col>
                    })
                }

            </Row>
            <div className={styles.titleNewBox}>
                <div>支付宝本地推计划合作</div>
                <img src={newTitle3} alt="" />
            </div>
            <div className={styles.contentBox}>
                1、支付宝依托分效科技数字屏幕投放平台，开展三年50城的拓城计划，实现规模化盈收效益。<br /> 2、结合线上人群标签数据+线下出行大流量入口构建线上线下一张网实现网络矩阵全案整合营销的新模式。<br /> 3、支付宝内部强大的商业化变现资源和渠道支撑各种模式的新玩法尝试。
            </div>
            <div className={styles.colorBox}>
                <div className={styles.colorTitleBox}>城市情况</div>
                <div className={styles.colorContentBox}>
                    <img src={newCar} alt="" />
                    <div>全国57个头腰城市中有33个城市具备接入条件</div>
                </div>
                <div className={styles.colorContentBox}>
                    <img src={newCar} alt="" />
                    <div>33城可接入存量10W辆车、15W屏幕、2729万客流</div>
                </div>
                <div className={styles.colorTitleBox}>行业痛点</div>
                <div className={styles.colorContentBox}>
                    <img src={newCar} alt="" />
                    <div>业主侧 经济下行、客流锐减，低价抛售，价值降至“谷底”</div>
                </div>
                <div className={styles.colorContentBox}>
                    <img src={newCar} alt="" />
                    <div>市场侧 各地业主资源分散，无法形成全国“网络矩阵”投放</div>
                </div>
            </div>
            <div className={styles.roundFlexBox}>
                {
                    roundFlexList.map(ele=>{
                        return <div className={styles.roundThing} style={{color:ele.color,backgroundColor:ele.bgc}}>
                    <div className={styles.title}>{ele.title}</div>
                    <div className={styles.number}>{ele.number}</div>
                    <div className={styles.content}>{ele.content}</div>
                </div>
                    })
                }
                
            </div>
            <div className={styles.roundFlexBox}>
                {
                    roundFlexList2.map(ele=>{
                        return <div className={styles.roundThing} style={{color:ele.color,backgroundColor:ele.bgc}}>
                    <div className={styles.title}>{ele.title}</div>
                    <div className={styles.number}>{ele.number}</div>
                    <div className={styles.content}>{ele.content}</div>
                </div>
                    })
                }
                
            </div>


            {/* <div className={styles.panel1}>
                <p>
                    通过数字化改革<br />
                    将传统线下出行场景作为流量池<br />
                    将自然出行人群作为流量用户<br />
                    以空间资源、媒介内容、精准触达、多样合作为优势<br />
                    完成流量转化的数字化营销服务平台
                </p>
            </div>

            <div className={styles.panel2}>
                <div className={styles.content}>
                    <h4 className={styles.title}>我们的优势</h4>
                    <img src={title1} alt="" className={styles.title_img} />
                    <p>广电系统&公交移动电视电视运营合作商&上市公司深度战略合作</p>
                    <img src={img1} alt="" className={styles.logo_img} />
                    <div className={styles.advantageList}>
                        {
                            advantageList.map(item => (
                                <div className={styles.card}>
                                     <h4>{item.title}</h4>
                                     <p>{item.desc}</p>
                                    <img src={item.icon} alt={item.title} />
                                    
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div> */}

            <div className={styles.panel3}>
                <h4 className={styles.title}>媒介特征</h4>
                <img src={title2} alt="" className={styles.title_img} />
                <p className={styles.desc}>公交广告“五大”不容忽视的特性</p>
                <div className={styles.box}>
                    {
                        mediaCharacteristic.map(item => (
                            <div className={styles.flex}>
                                {
                                    item.map(val => (
                                        <div className={styles.mediaCharacteristic} span={8}>
                                            {val.icon && <img src={val.icon} alt="" />}
                                            <p>{val.title}</p>
                                        </div>
                                    ))
                                }
                            </div>
                        ))
                    }
                </div>
            </div>

            <div className={styles.panel4}>
                <div className={styles.content}>
                    <h4 className={styles.title}>多样内容</h4>
                    <img src={title3} alt="" className={styles.title_img} />
                    <div className={styles.diverseContent}>
                        {
                            diverseContentList.map(item => (
                                <div className={styles.card}>
                                    <img src={item.icon} alt={item.title} className={styles.icon} />
                                    <div className={styles.rightPanel}>
                                        <h4>{item.title}</h4>
                                        <p className={styles.desc}>{item.desc}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>

            <div className={styles.panel5}>
                <div className={styles.content}>
                    <h4 className={styles.title}>部分节目展示</h4>
                    <img className={styles.title_img} src={title4} alt="" />
                    <div className={styles.videoSwiper}>
                        <Swiper
                            modules={[EffectCoverflow, Navigation, Autoplay]}
                            loop={true}
                            effect='coverflow'
                            grabCursor={true}
                            centeredSlides={true}
                            slidesPerView={'auto'}
                            navigation={false}
                            pagination={false}
                            className="mySwiper"
                            coverflowEffect={{
                                rotate: 0,
                                stretch: 100,
                                depth: 100,
                                modifier: 1,
                                slideShadows: true,
                            }}
                            onSlideChange={(e) => {
                                if (e.clickedSlide) {
                                    const myVideo = e.clickedSlide.childNodes[0].childNodes[0]
                                    if (myVideo) {
                                        myVideo.pause();
                                        myVideo.currentTime = 0
                                    }
                                }
                                setShowControls(false);
                            }}
                        >
                            {
                                programVideoList.map(item => (
                                    <SwiperSlide className={styles.swiper_slide}>
                                        <div onClick={(e) => playVideo(e)}>
                                            {
                                                item.poster ?
                                                    <video
                                                        width='100%'
                                                        controls={showControls}
                                                        poster={item.poster}
                                                    >
                                                        <source src={item.url} type="video/mp4" />
                                                    </video> :
                                                    <video
                                                        width='100%'
                                                        controls={showControls}
                                                    >
                                                        <source src={item.url} type="video/mp4" />
                                                    </video>
                                            }
                                            {
                                                !showControls &&
                                                <>
                                                    <div className={styles.videoPlay}>
                                                        <img src={videoPlay} alt="" />
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Medium;
