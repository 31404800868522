import React from 'react';
import RouterConfig from './Router/index';
import './App.less';

let isMobile = false;
if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    isMobile = true;
}


function App() {
  return (
    <div className={ isMobile ? 'MobileApp' : 'App'}>
      <RouterConfig />
    </div>
  );
}

export default App;
