import React from 'react';
import qrCode from '@/assets/images/qrCode.png';
import styles from './index.module.less';

const Footer = () => (
    <div className={styles.footer}>
        <div className={styles.footer_content}>
            <div className={styles.leftPlate}>
                <p className={styles.title}>联系我们</p>
                <p className={styles.desc}>招商热线：17705812754</p>
                <p className={styles.desc}>联系地址：上海市徐汇区田林路130号20号楼</p>
                <p className={styles.desc}>联系邮箱：fx@fenxiaotech.com</p>
                <p className={styles.desc}>杭州总部地址：杭州市西湖区西溪首座A1-1-617</p>
            </div>
            <div className={styles.rightPlate}>
                <div className={styles.rightPlate_img}>
                    <img src={qrCode} alt=""  />
                    <p className={styles.desc} style={{ textAlign: 'center'}}>招商合作</p>
                </div>
                <div className={styles.rightPlate_content}>
                    <div className={styles.coveredCity}>
                        <p className={styles.title}>已覆盖城市：</p>
                        <p className={styles.desc}>北京、长沙、合肥、青岛、西安、苏州、杭州、<br/>深圳、济南、重庆、福州、宁波、烟台</p>
                    </div>
                    <div className={styles.planCity}>
                        <p className={styles.title}>筹备中城市：</p>
                        <p className={styles.desc}>上海、厦门、成都、昆明、南京、无锡、<br/>大连、广州、天津、郑州、贵阳、武汉……</p>
                    </div>
                </div>
            </div>
        </div>
        <div className={styles.copyRight}>
            <p className={styles.copyRight_content}>
                杭州分效科技有限公司 CopyRight@2024
                <a style={{ color: '#ffffff' }} target= '_blank' href="https://beian.miit.gov.cn/?spm=a2cmq.17630005.0.0.42d979feE8056P#/Integrated/index”">浙ICP备2024096404号-1</a>
            </p>
        </div>
    </div>
);

export default Footer;
