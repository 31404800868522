
import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { Divider, Button } from 'antd';
import axios from 'axios';
import styles from './index.module.less';
import banner from '@/assets/images/bannerMobile.png';
import config from '@/config';

const Detail = (props) => {
    const [detail, setDeatil] = useState({});
    const [newsContent, setNewsContent] = useState('');

    useEffect(() => {
       const newsId = props.match?.params.newsId;
        getDetailData(newsId)
    },[props.match?.params.newsId])

    function getDetailData (newsId){
        axios({
            url: `${config.baseAPI}/site/news/detail`,
            data:  {
                newsId
            },
            method: 'post'
        }).then(res => {
            if(res.status == 200){
                getNewsCotent(res.data.data.content);
                setDeatil(res.data.data);
            }
        }
        ).catch(
            err => console.error(err)
        )
    }

    function getNewsCotent(url) {
        axios({
            url: url,
            method: 'get',
            headers: {
                'content-type': 'text/plain;charset=utf-8;'
            },
        }).then(res => {
            setNewsContent(res.data);
        }).catch(
            err => {
                setNewsContent('');
                console.error(err)
            }
        )
    }

    function toDetail (newsId){
        props.history.push({
            pathname: `/news/detail/${newsId}`,
            state:{
                newsId: newsId
            }
        })
    }

    function goBack (){
        props.history.push('/news')
    }

    return (
        <div>
            <div className={styles.newsDetail}>
                <div className={styles.banner}>
                    <img src={banner} alt="" />
                    <div className={`${styles.banner_title} animateTopFade`}>
                        <h4>智能出行多媒体营销 让线下营销更易触达</h4>
                    </div>
                </div>
                <div className={styles.content}>
                    <h4 className={styles.title}>{detail?.title}</h4>
                    <p className={styles.time}>{detail?.createdTime}</p>
                    <p className={styles.desc} dangerouslySetInnerHTML={{__html: newsContent}}></p>
                    <Divider />
                    <div className={styles.footer}>
                        <div className={styles.mune}>
                            {
                                detail?.lastPage && <p>上一篇：<a onClick={() => toDetail(detail?.lastPage.newsId)}>{detail?.lastPage.title}</a> </p>
                            }
                            {
                                detail?.nextPage && <p>下一篇：<a onClick={() => toDetail(detail?.nextPage.newsId)}>{detail?.nextPage.title}</a> </p>
                            }
                        </div>
                        <Button type='default' onClick={goBack} className={styles.btn}>返回列表</Button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default withRouter(Detail);
