let baseAPI = '/';

if (process.env.NODE_ENV === 'development') {
  baseAPI = 'http://192.168.8.241:8800';
} 
if (process.env.NODE_ENV === 'production') {
  baseAPI = 'https://fenxiaotech.com';
}

export default {
  baseAPI
}