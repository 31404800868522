
import React, { useState, useEffect } from 'react';
import { Button, Collapse, Spin, Divider } from 'antd';
import { withRouter } from "react-router-dom";
import banner from '@/assets/images/joinUs/bannerMobile.png';
import axios from 'axios';
import styles from './index.module.less';
import { jionUsList } from '@/pagesMobile/const';
import config from '@/config';
import tabIcon from '@/assets/images/joinUs/tab.png';
import { CaretDownOutlined } from '@ant-design/icons';
import tabActiveIcon from '@/assets/images/joinUs/tab_active.png';
import { Popup } from 'antd-mobile';

const { Panel } = Collapse;

const JoinUs = (props) => {
    const [tableList, setTableList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [jobTypeList, setJobTypeList] = useState([]);
    const [currentIndex, setCurrentIndex] = useState('');
    const [visible, setVisible] = useState(false)
   

    function getRecruitList(params) {
        axios({
            url: `${config.baseAPI}/site/recruit/list`,
            data: params,
            method: 'post'
        }).then(res => {
            setLoading(false);
            setVisible(false);
            const { list } = res.data.data;
            if (res.status == 200) {
                list.map(item => {
                    item.key = item.recruitId
                })
                setTableList(list)
            }
        }
        ).catch(
            err => console.error(err)
        )
    }

    useEffect(() => {
        getRecruitList({
            page: 1,
            pageSize: 10
        })
        getJobType();
    }, [])

    function getJobType() {
        axios({
            url: `${config.baseAPI}/site/recruit/job-type-num`,
            data: {},
            method: 'post'
        }).then(res => {
            const { code, data } = res.data;
            if(code == 0){
                const result = data.reduce((res, cur) => {
                      return res + Number(cur.num)
                },0);
                const arr = {
                    jobType: '全部',
                    num: result
                }
                data.unshift(arr)
                setJobTypeList(data)
            }
        }
        ).catch(
            err => console.error(err)
        )
    }

    function apply(item) {
        props.history.push({
            pathname: '/join_add',
            state: {
                applyInfo: item
            }
        })
    }

    function callback(key) {
        console.log(key);
    }

    function filterJob (index, item){
        setCurrentIndex(index);
        getRecruitList({
            page: 1,
            pageSize: 10,
            jobType: item.jobType == '全部' ? undefined : item.jobType
        })
    }

    return (
        <div>
            <div className={styles.join}>
                <div className={styles.banner}>
                    <img src={banner} alt="" />
                    <div className={`${styles.banner_title} animateTopFade`}>
                        <h4>分效科技</h4>
                        <h4>期待与您的合作</h4>
                    </div>
                </div>
                <div className={styles.panel1}>
                    <div className={styles.content}>
                        {
                            jionUsList.map(item => (
                                <div className={styles.flex}>
                                    <img src={item.icon} alt={item.title} />
                                    <div className={styles.flex_right}>
                                        <h4>{item.title}</h4>
                                        <p>{item.desc}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className={styles.panel2}>
                    <div className={styles.jobType} onClick={() => setVisible(true)}>筛选类别<CaretDownOutlined /></div>
                    <Popup
                    visible={visible}
                    onMaskClick={() => {
                        setVisible(false)
                    }}
                    position='top'
                    bodyStyle={{ height: '25vh' }}
                    >
                        <div className={styles.jobTypeContainer}>
                            <div className={styles.jobTypeTitle}>筛选类别</div>
                            <Divider />
                            {
                                jobTypeList && jobTypeList?.map((item, index) => {
                                    return (
                                        <span 
                                            className={styles.jobTypeBox} 
                                            style={currentIndex == index? {background: 'rgba(0, 122, 255, 0.2)', color: '#007AFF'} : {}}  
                                            onClick={() => filterJob(index,item)}
                                        >
                                            <span>{item.jobType}</span>
                                            <span>({item.num})</span>
                                        </span>
                                    )
                                } 
                                )
                            }
                        </div>
                    </Popup>
                    <Spin spinning={loading}>
                        <Collapse onChange={callback}  ghost expandIcon={({ isActive }) => null}>
                            {
                                tableList.map(item => (
                                    <Panel
                                        header={<div className={styles.panelBox}>
                                            <div className={styles.panelBox_left}>
                                                <img src={tabIcon} alt="" />
                                                <div>
                                                    <p className={styles.panelTitle}>{item.jobName}</p>
                                                    <p className={styles.panelDesc}>{`${item.jobType}.${item.jobAddress}.${item.peopleTotal}人`}</p>
                                                </div>
                                            </div>
                                            <Button type='primary' className={styles.btn} onClick={() => apply(item)}>申请职位</Button>
                                        </div>}
                                        key={item.recruitId}
                                    >
                                        <div className={styles.panelContent}>
                                            <div>
                                                <p className={styles.dutyTitle}>岗位职责:</p>
                                                <p className={styles.dutyContent}>{item.duty == '暂无' ? '' : item.duty}</p>
                                            </div>
                                            <div style={{ marginTop: 24 }}>
                                                <p className={styles.dutyTitle}>任职要求:</p>
                                                <p className={styles.dutyContent}>{item.requirement == '暂无' ? '' : item.requirement}</p>
                                            </div>
                                        </div>
                                    </Panel>
                                ))
                            }
                        </Collapse>
                    </Spin>
                </div>
            </div>
        </div>
    )
};

export default withRouter(JoinUs);