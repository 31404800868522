import Home from '@/pages/Home';
import About from '@/pages/About';
import Medium from '@/pages/Medium';
import Product from '@/pages/Product';
import News from '@/pages/News';
import NewsDetail from '@/pages/News/detail';
import Cooperation from '@/pages/Cooperation';
import JoinUs from '@/pages/JoinUs';
import ContactUs from '@/pages/ContactUs';
import MobileHome from '@/pagesMobile/Home';
import MobileAbout from '@/pagesMobile/About';
import MobileMedium from '@/pagesMobile/Medium';
import MobileProduct from '@/pagesMobile/Product';
import MobileJoinUs from '@/pagesMobile/JoinUs';
import MobileApply from '@/pagesMobile/JoinUs/apply';
import MobileCooperation from '@/pagesMobile/Cooperation';
import MobileContactUs from '@/pagesMobile/ContactUs';
import MobileNews from '@/pagesMobile/News'
import MobileNewsDetail from '@/pagesMobile/News/detail'
import Test from '@/pages/About/test'



export const routePc = [
    {
        path: '/',
        component: Home,
        children: [
            // {
            //     path: '/',
            //     component: Home,
            //     exact:true
            // },
            {
                name: '首页',
                path: '/home',
                component: Home,
            },
            {
                name: '关于分效',
                path: '/about',
                component: About,
            },
            {
                name: '媒介资源',
                path: '/medium',
                component: Medium,
            },
            {
                name: '产品服务',
                path: '/product',
                component: Product,
            },
            {
                name: '新闻中心',
                path: '/news',
                component: News,
            },
            {
                name: '新闻详情',
                path: '/news/detail/:newsId',
                component: NewsDetail,
                hide: true
            },
            {
                name: '合作案例',
                path: '/cooperation',
                component: Cooperation,
            },
            {
                name: '加入分效',
                path: '/join',
                component: JoinUs,
            },
            {
                name: '联系我们',
                path: '/contactUs',
                component: ContactUs,
            },
        ],
    },
];

export const routerMobile = [
    {
        path: '/',
        component: MobileHome,
        children: [
            // {
            //     path: '/',
            //     component: Home,
            //     exact:true
            // },
            {
                name: '首页',
                path: '/home',
                component: MobileHome,
            },
            {
                name: '关于分效',
                path: '/about',
                component: MobileAbout,
            },
            {
                name: '媒介资源',
                path: '/medium',
                component: MobileMedium,
            },
            {
                name: '产品服务',
                path: '/product',
                component: MobileProduct,
            },
            {
                name: '新闻中心',
                path: '/news',
                component: MobileNews,
            },
            {
                name: '新闻详情',
                path: '/news/detail/:newsId',
                component: MobileNewsDetail,
                hide: true
            },
            {
                name: '合作案例',
                path: '/cooperation',
                component: MobileCooperation,
            },
            {
                name: '加入分效',
                path: '/join',
                component: MobileJoinUs,
            },
            {
                name: '加入分效',
                path: '/join_add',
                component: MobileApply,
                hide: true
            },
            {
                name: '联系我们',
                path: '/contactUs',
                component: MobileContactUs,
            },
            {
              name: '古香摄影',
              path: '/h5',
              component: Test,
              hide: true
            },
        ],
    },
];
