import React, { useEffect, useState } from 'react';
import { List, Empty } from 'antd';
import banner from '@/assets/images/cooperate/banner.jpg';
import title1 from '@/assets/images/cooperate/title1.png';
import styles from './index.module.less';
import axios from 'axios';
import config from '@/config';


const Cooperation = () => {
    const [casesList, setCasesList] = useState([]);
    const [total, setTotal] = useState(0);

    function getCasesList (params){
        axios({
            url: `${config.baseAPI}/site/cooperation/cases/list`,
            data: params,
            method: 'post'
        }).then(res => {
            if(res.status == 200){
                const { items, itemCount } = res.data.data.list
                setCasesList(items);
                setTotal(itemCount)
            }
        }
        ).catch(
            err => console.error(err)
        )
    }
    useEffect(()=> {
        getCasesList({
            page: 1,
            pageSize: 8
        })
    },[])

    return(
    <div className={styles.contact}>
        <div className={styles.banner}>
            <img src={banner} alt="" />
            <div className={`${styles.banner_title} animateTopFade`}>
                <h4>为线下营销产业链带来新革命</h4>
            </div>
        </div>
        <div className={styles.panel1}>
            <div className={styles.content}>
                <h4>部分合作案例</h4>
                <img src={title1} alt="" className={styles.title_img} />
                <div className={styles.cooperationCase}>
                    <List
                        itemLayout="vertical"
                        size="large"
                        pagination={{
                            onChange: page => {
                                getCasesList({ page, pageSize: 8 })
                            },
                            total: total,
                            pageSize: 8,
                        }}
                        locale={{emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='暂无内容' />}}
                        dataSource={casesList}
                        renderItem={(item, index) => (
                            <List.Item key={index}>
                                <div className={styles.box}>
                                    <div className={styles.bgTemplate}>
                                        <img src={item.imageAddress} alt="" />
                                    </div>
                                    <h4 className={styles.templateTitle}>{item.name}</h4>
                                </div>
                            </List.Item>
                        )}
                    />
                </div>
            </div>
        </div>
    </div>
)};

export default   Cooperation;
