import React from 'react';
import banner from '@/assets/images/contact/bannerMobile.png';
import title from '@/assets/images/contact/title.png';
import icon1 from '@/assets/images/contact/icon1.png';
import icon2 from '@/assets/images/contact/icon2.png';
import icon3 from '@/assets/images/contact/icon3.png';
import styles from './index.module.less';
import mark4 from '@/assets/images/contact/mark4.png';
import { cityList } from '@/pagesMobile/const';

const ContactUs = () => {

    return(
    <div className={styles.contact}>
        <div className={styles.banner}>
            <img src={banner} alt="" />
            <div className={`${styles.banner_title} animateTopFade`}>
                <h4>联系分效、多城联动</h4>
            </div>
        </div>
        <div className={styles.panel1}>
            <div className={styles.content}>
                <h4 className={styles.title}>联系我们</h4>
                <img src={title} alt="" />
                <div className={styles.contactNumber}>
                    <div> <img src={icon1} alt="" /> <span>联系人：汪女士</span></div>
                    <div><img src={icon2} alt="" /> <span>电话：17705812754</span></div>
                    <div><img src={icon3} alt="" /> <span>邮箱：fx@fenxiaotech.com</span></div>
                </div>
                <div className={styles.map}>
                    <img src="https://busmedia.obs.cn-east-3.myhuaweicloud.com/public/mapPhone.png" alt="" />
                </div>
                <div className={styles.cityList}>
                    {
                        cityList.map((val, idx)=> (
                            <div>
                                <img src={ mark4 } alt="" />
                                <h4>{val.name}</h4>
                                <p>{val.address}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    </div>
)};

export default   ContactUs;
