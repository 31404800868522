import React, { useEffect, useRef } from 'react';
import styles from './index.module.less';
import * as echarts from 'echarts';
import { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useCountUp } from 'react-countup';
import 'swiper/less'
import 'swiper/less/navigation'
import 'swiper/less/pagination'
import image1 from '@/assets/images/home/moblileMage1.png';
import image2 from '@/assets/images/home/image2.png';
import image3 from '@/assets/images/home/image3.png';
import image4 from '@/assets/images/home/image4.png';
import icon1 from '@/assets/images/home/icon1.png';
import icon2 from '@/assets/images/home/icon2.png';
import icon3 from '@/assets/images/home/icon3.png';
import icon4 from '@/assets/images/home/icon4.png';
import icon5 from '@/assets/images/home/icon5.png';
import icon6 from '@/assets/images/home/icon6.png';
import icon7 from '@/assets/images/home/icon7.png';
import iconNew1 from '@/assets/images/home/iconNew1.png';
import iconNew2 from '@/assets/images/home/iconNew2.png';
import iconNew3 from '@/assets/images/home/iconNew3.png';
import iconNew4 from '@/assets/images/home/iconNew4.png';
import iconNew5 from '@/assets/images/home/iconNew5.png';
import iconNew6 from '@/assets/images/home/iconNew6.png';
import chinaJson from './china.json';
import { Row, Col } from 'antd'

const Home = () => {
    const panel4Ref = useRef(null);
    const cityNumber = useRef(12); // 覆盖城市
    const busNumber = useRef(50000); // 覆盖车辆
    const screenNumber = useRef(100000); // 屏幕数量
    const peopleNumber = useRef(6400); // 覆盖人数
    const cityNumberTime = useRef(0.75); // 覆盖城市动画时长
    const busNumberTime = useRef(2.75); // 覆盖车辆动画时长
    const screenNumberTime = useRef(2.75); // 屏幕数量动画时长
    const peopleNumberTime = useRef(1.75); // 覆盖人数动画时长
    const customerLength = 24; // 合作伙伴数量
    const logoPath = 'assets/images/home/customer';
    const logoArray = useRef([]);
    let childArray = [];
    for (let num = 0; num < customerLength; num++) {
        if (num % 8 === 0) {
            childArray = logoArray.current[num / 8] = [];
        }
        childArray.push(`${logoPath}/${num}.png`);
    }

    // 数字滚动
    const isCountAnimateRef = useRef(false);
    const countConfig = {
        start: 0,
        separator: ",",
        decimals: 0,
        decimal: "."
    };
    const countUpCityRef = useRef(null);
    const { start: cityStart } = useCountUp({
        ...countConfig,
        ref: countUpCityRef,
        end: cityNumber.current,
        duration: cityNumberTime.current
    });
    const countUpBusRef = useRef(null);
    const { start: busStart } = useCountUp({
        ...countConfig,
        ref: countUpBusRef,
        end: busNumber.current,
        duration: busNumberTime.current
    });
    const countUpScreenRef = useRef(null);
    const { start: screenStart } = useCountUp({
        ...countConfig,
        ref: countUpScreenRef,
        end: screenNumber.current,
        duration: screenNumberTime.current
    });
    const countUpPeopleRef = useRef(null);
    const { start: peopleStart } = useCountUp({
        ...countConfig,
        ref: countUpPeopleRef,
        end: peopleNumber.current,
        duration: peopleNumberTime.current
    });

    function initMap() {
        const mapChart = echarts.init(document.getElementById('mapChart'));
        echarts.registerMap('china', chinaJson);
        //可以在地图上显示的城市的坐标信息
        const geoCoordMap = {
            '长沙': [113.0823, 28.2568],
            '杭州': [119.5313, 29.8773],
            '苏州': [120.592412, 31.303564],
            '合肥': [117.29, 32.0581],
            '北京': [116.4551, 40.2539],
            '青岛': [120.389455, 36.072227],
            '西安': [108.946466, 34.347269],
            '深圳': [114.064552, 22.548457],
            '济南': [117.126399, 36.656554],
            '重庆': [106.55, 29.57],
            '福州': [119.30347, 26.080429],
            '宁波': [121.630843, 29.866068],
            '烟台': [121.454415, 37.470038],
        };
        // 杭州为中心店，数据中name的城市名称必须与geoCoordMap中城市名称一致, 不然关联不上，杭州到各地区的线路
        const HFData = [
            [{ name: '杭州' }, { name: '杭州', value: 118 }],
            [{ name: '杭州' }, { name: '长沙', value: 66 }],
            [{ name: '杭州' }, { name: '苏州', value: 66 }],
            [{ name: '杭州' }, { name: '合肥', value: 66 }],
            [{ name: '杭州' }, { name: '北京', value: 66 }],
            [{ name: '杭州' }, { name: '青岛', value: 66 }],
            [{ name: '杭州' }, { name: '西安', value: 66 }],
            [{ name: '杭州' }, { name: '深圳', value: 66 }],
            [{ name: '杭州' }, { name: '济南', value: 66 }],
            [{ name: '杭州' }, { name: '重庆', value: 66 }],
            [{ name: '杭州' }, { name: '福州', value: 66 }],
            [{ name: '杭州' }, { name: '宁波', value: 66 }],
            [{ name: '杭州' }, { name: '烟台', value: 66 }],
        ];
        const planePath = 'circle';
        // push进去线路开始-结束地点-经纬度
        const convertData = function (data) {
            const res = [];
            for (let i = 0; i < data.length; i++) {
                const dataItem = data[i];
                const fromCoord = geoCoordMap[dataItem[0].name];
                const toCoord = geoCoordMap[dataItem[1].name];
                if (fromCoord && toCoord) {
                    res.push([{
                        coord: fromCoord
                    }, {
                        coord: toCoord
                    }]);
                }
            }
            return res;
        };
        const color = ['#147BFF', '#FFFFA8', '#147BFF']; //圆圈和字的颜色，线的颜色，箭头颜色
        // 数据
        const series = [];
        // 遍历由杭州到其他城市的线路
        [
            ['杭州', HFData]
        ].forEach(function (item, i) {
            // 配置
            series.push({
                // 系列名称，用于tooltip的显示
                name: item[0],
                type: 'lines',
                zlevel: 1, // 用于 Canvas 分层，不同zlevel值的图形会放置在不同的 Canvas 中
                // effect出发到目的地 的白色尾巴线条
                // 线特效的配置
                effect: {
                    show: true,
                    period: 6, // 特效动画的时间，单位为 s
                    trailLength: 0, // 特效尾迹的长度。取从 0 到 1 的值，数值越大尾迹越长
                    color: '#147BFF', // 移动箭头颜色
                    symbol: planePath,
                    symbolSize: 3 // 特效标记的大小
                },
                // lineStyle出发到目的地 的线条颜色
                lineStyle: {
                    normal: {
                        color: color[i],
                        width: 0,
                        curveness: 0.1 //幅度
                    }
                },
                data: convertData(item[1]) //开始到结束数据
            }, {
                //出发地信息
                name: item[0],
                type: 'lines',
                zlevel: 2,
                effect: {
                    show: true,
                    period: 6,
                    trailLength: 0,
                    symbol: planePath,
                    symbolSize: 1
                },
                lineStyle: {
                    normal: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                            offset: 0,
                            color: 'rgba(20,123,255,0.5)'// 出发
                        }, {
                            offset: 1,
                            color: 'rgba(20,123,255,0.5)' // 结束 颜色
                        }], false),
                        width: 1.5,
                        opacity: 0.4,
                        curveness: 0.2
                    }
                },
                data: convertData(item[1])
            }, {
                // 目的地信息
                name: item[0],
                type: 'effectScatter',
                coordinateSystem: 'geo',
                zlevel: 2,
                rippleEffect: {
                    brushType: 'stroke'
                },
                label: {
                    normal: {
                        show: true,
                        position: 'right',
                        formatter: '{b}'
                    }
                },
                symbolSize: function (val) {
                    return val[2] / 8;
                },
                itemStyle: {
                    normal: {
                        color: color[i],
                    }
                },
                data: item[1].map(function (dataItem) {
                    return {
                        name: dataItem[1].name,
                        value: geoCoordMap[dataItem[1].name].concat([dataItem[1].value])
                    };
                })
            });
        });
        // 指定相关的配置项和数据
        const mapBoxOption = {
            backgroundColor: 'transparent',
            geo: {
                map: 'china',
                roam: false, // 是否开启鼠标缩放和平移漫游。默认不开启。如果只想要开启缩放或者平移，可以设置成 'scale' 或者 'move'。设置成 true 为都开启
                aspectScale: 0.75,
                zoom: 1.20,
                label: {
                    normal: {
                        show: false,
                        textStyle: {
                            color: '#00a0c9'
                        }
                    },
                    emphasis: { // 对应的鼠标悬浮效果
                        show: false,
                        textStyle: {
                            color: "#00a0c9"
                        }
                    }
                },
                itemStyle: {
                    normal: {
                        areaColor: '#ffffff',
                        borderColor: '#CCCCCC'
                    },
                    emphasis: {
                        borderColor: '#CCCCCC',
                        areaColor: "#ffffff",
                    }
                }
            },
            series: series
        };
        // 使用制定的配置项和数据显示图表
        mapChart.setOption(mapBoxOption);
    }

    function scrollEvent() {
        const wHeight = window.innerHeight; // 浏览器可视高度
        const scrollY = window.scrollY; // 滚动距离
        const panelTop = panel4Ref.current.offsetTop; // 对应数字的位置
        const arrivedTop = panelTop - (scrollY + wHeight - wHeight * 0.4); // 判断是否已经滚动到对应的位置了
        if (arrivedTop <= 0 && !isCountAnimateRef.current) {
            isCountAnimateRef.current = true;
            cityStart();
            busStart();
            screenStart();
            peopleStart();
        }
    }

    useEffect(() => {
        initMap();
        window.addEventListener('scroll', scrollEvent);
        return () => {
            window.removeEventListener('scroll', scrollEvent);
        }
    }, []);

    const newIconList = [
        {
            img: iconNew1,
            title: '技术优势',
            text: '公交传统屏幕数字升级改造方案，实现线上线下全链路数字化营销'
        },
        {
            img: iconNew2,
            title: '内容优势',
            text: '全案广告策划，内容和活动策划创作能力'
        },
    ]
    const newIconList2 = [
        {
            img: iconNew3,
            title: '运营优势',
            text: '智能广告平台，全平台线上线下智能化投放，流量运营能力'
        },
        {
            img: iconNew4,
            title: '资源优势',
            text: '广电集团独家运营授权线下传播牌照，支付宝授权线上广告运营权，上市公司资源加持'
        },
    ]

    return (
        <div>
            <div className={styles.homeContainer}>
                <div className={styles.banner}>
                    <div className={`${styles.text} ${styles.animateTopFade}`}>
                    公共出行数字营销领跑者
                    </div>
                </div>
                <div className={styles.panel1}>
                    <div className={styles.maxContent}>
                        <div className={styles.content}>
                            <div className={styles.panelTitle}>我们的优势</div>
                            <div className={styles.panelText}>
                                广电系统&公交移动电视运营合作商&上市公司深度战略合作
                            </div>
                            <img className={styles.image1} src={image1} />
                        </div>
                        <Row style={{ marginTop: 24 }} justify="center" gutter={10} align="middle" className={styles.newBox}>
                            {
                                newIconList.map(ele => {
                                    return <Col span={12} >
                                        <div className={styles.thing1}>
                                            <img src={ele.img} alt="" />
                                            <div className={styles.title}>{ele.title}</div>
                                            <div className={styles.textContent}>{ele.text}</div>
                                        </div>

                                    </Col>
                                })
                            }
                        </Row>
                        <Row style={{ marginTop: 16 }} justify="center" gutter={10} align="middle" className={styles.newBox}>
                            {
                                newIconList2.map(ele => {
                                    return <Col span={12} >
                                        <div className={[styles.thing1, styles.thing2].join(' ')}>
                                            <img src={ele.img} alt="" />
                                            <div className={styles.title}>{ele.title}</div>
                                            <div className={styles.textContent}>{ele.text}</div>
                                        </div>

                                    </Col>
                                })
                            }
                        </Row>
                        {/* <div className={styles.newBox}>
                            {
                                newIconList.map(ele=>{
                                    return <div className={styles.thing1}>
                                
                            </div>
                                })
                            }

                        </div> */}
                    </div>
                </div>
                <div className={styles.panel2}>
                    <div className={styles.maxContent}>
                        <div className={styles.panelTitle}>为什么选择公交媒体投放</div>
                        <div className={styles.carNewBox}>
                            <div className={styles.titleBox}>
                                <img src={iconNew5} alt="" />
                                <div>全国中心城市公共交通客运总量</div>
                            </div>
                            <div className={styles.labelContent}> 线下自带流量场景 只要人在外活动就会不断产生新流量</div>
                            <div className={styles.centerBox}>
                                <div className={styles.lineBox}>
                                    <div className={styles.number}>673.5亿</div>
                                    <div className={styles.column1}></div>
                                    <div className={styles.txt}>2019年度</div>
                                </div>
                                <div className={styles.lineBox}>
                                    <div className={styles.number}>441.5亿</div>
                                    <div className={styles.column2}></div>
                                    <div className={styles.txt}>2020年度</div>
                                </div>
                                <div className={styles.lineBox}>
                                    <div className={styles.number}>529.5亿</div>
                                    <div className={styles.column3}></div>
                                    <div className={styles.txt}>2021年度</div>
                                </div>
                            </div>
                            <div className={styles.titleBox} style={{marginTop:24}}>
                                <img src={iconNew6} alt="" />
                                <div>全国中心城市公共交通客运总量</div>
                            </div>
                            <div className={styles.labelContent}> 线上大型APP流量池 不受自然条件影响，主动推送精准触达</div>
                            <div className={styles.centerBgcBox}>
                                    <div className={styles.lineFlexBox}>
                                        <div className={styles.box}>
                                            <div className={styles.numbers}>1000 <span>+</span></div>
                                            <div className={styles.textBox}>全国城市覆盖数</div>
                                        </div>
                                        <div className={styles.box}>
                                            <div className={styles.numbers}>4亿 <span>+</span></div>
                                            <div className={styles.textBox}>出行服务用户数</div>
                                        </div>
                                    </div>
                                    <div className={styles.lineFlexBox}>
                                        <div className={styles.box}>
                                            <div className={styles.numbers}>90%</div>
                                            <div className={styles.textBox}>乘车码覆盖率</div>
                                        </div>
                                        <div className={styles.box}>
                                            <div className={styles.numbers}>2亿</div>
                                            <div className={styles.textBox}>乘车码用户</div>
                                        </div>
                                    </div>
                                </div>
                        </div>

                        <div className={styles.flex}>
                            <div className={styles.content}>
                                <img className={styles.icon} src={icon1} />
                                <div className={styles.contentTitle}>公共出行流量大</div>
                                <div className={styles.contentText}>
                                    全国拥有公交车近70万辆，客运量近700亿人次，高频、刚需，有稳定的大流量
                                </div>
                                <div className={styles.divline}></div>
                            </div>
                            <div className={styles.content}>
                                <img className={styles.icon} src={icon2} />
                                <div className={styles.contentTitle}>人群触达率高</div>
                                <div className={styles.contentText}>
                                    公交覆盖了城乡主流商圈，城市覆盖率高达70%，受众覆盖面广，高流动性能让更多的消费者关注到
                                </div>
                            </div>
                        </div>
                        <div className={styles.flex}>
                            <div className={styles.content}>
                                <img className={styles.icon} src={icon3} />
                                <div className={styles.contentTitle}>最接近消费者</div>
                                <div className={styles.contentText}>
                                    公交车属于封闭空间，广告处于人们视线最佳范围，公交广告具有强迫阅读和反复阅读性，能让消费者留下较深印象
                                </div>
                                <div className={styles.divline}></div>
                            </div>
                            <div className={styles.content}>
                                <img className={styles.icon} src={icon4} />
                                <div className={styles.contentTitle}>线上线下结合</div>
                                <div className={styles.contentText}>
                                    通过数字化，将传统户外广告和新媒体广告有机结合，提供有趣有料的内容，全方位宣传企业品牌
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.panel3}>
                    <div className={styles.maxContent}>
                        <div>
                            <div className={styles.panelTitle}>关于分效</div>
                            <img className={styles.image4} src={image4} alt='关于分效' />
                            <div className={styles.content}>
                                <div className={styles.contentText}>
                                    上海分效科技是一家专注于物联网广告服务的科技公司，通过“IOT+SAAS”改造传统屛媒，基于开放平台连接广告主和媒体主两端，利用大数据平台为广告主提供精准高效的线下广告投放服务，同时运用多种营销解决方案帮助客户提升销售收入。
                                    <br /><br />
                                    分效科技和国内上市公司达成战略合作，成为了广电授权的独家运营商，深度服务全国各地广电进行智能化升级改造，实现一键智能化播控投放，打通线下户外广告和线上新媒体广告链路，为公共出行广告注入新活力。
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.iconList}`}>
                            <div className={styles.flex}>
                                <img className={styles.icon} src={icon5} />
                                <div className={styles.content}>
                                    <div className={styles.smallContentTitle}>使命</div>
                                    <div className={styles.contentText}>让线上线下数字营销变得更简单</div>
                                </div>
                            </div>
                            <div className={styles.flex}>
                                <img className={styles.icon} src={icon6} />
                                <div className={styles.content}>
                                    <div className={styles.smallContentTitle}>愿景</div>
                                    <div className={styles.contentText}>
                                    成为公共出行数字营销领跑者<br />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.flex}>
                                <img className={styles.icon} src={icon7} />
                                <div className={styles.content}>
                                    <div className={styles.smallContentTitle}>价值观</div>
                                    <div className={styles.contentText}>客户第一、诚信正直、高效协作、追求结果</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={panel4Ref} className={styles.panel4}>
                    <div className={styles.maxPanel}>
                        <div className={styles.maxContent}>
                            <div className={styles.panelTitle}>真诚服务 · 合作共赢</div>
                            <div className={styles.flex}>
                                <div className={styles.numberContent}>
                                    <div className={styles.number}>
                                        <span ref={countUpCityRef}></span>
                                    </div>
                                    <div className={styles.numberTips}>覆盖城市</div>
                                </div>
                                <div className={styles.numberContent}>
                                    <div className={styles.number}>
                                        <span ref={countUpBusRef}></span>
                                        <span>+</span>
                                    </div>
                                    <div className={styles.numberTips}>总车辆数</div>
                                </div>
                                <div className={styles.numberContent}>
                                    <div className={styles.number}>
                                        <span ref={countUpScreenRef}></span>
                                        <span>+</span>
                                    </div>
                                    <div className={styles.numberTips}>总屏幕数</div>
                                </div>
                                <div className={styles.numberContent}>
                                    <div className={styles.number}>
                                        <span ref={countUpPeopleRef}></span>
                                        <span>万+</span>
                                    </div>
                                    <div className={styles.numberTips}>日均覆盖人数</div>
                                </div>
                            </div>
                            <div id="mapChart" className={styles.mapChart}></div>
                        </div>
                    </div>
                </div>
                <div className={styles.panel5}>
                    <div className={styles.maxContent}>
                        <div className={styles.panelTitle}>合作客户</div>
                        <div className={styles.swpierContainer}>
                            <Swiper
                                modules={[Navigation, Pagination, Autoplay]}
                                loop={true}
                                autoplay={{ delay: 5000 }}
                                spaceBetween={0}
                                slidesPerView={1}
                                pagination={{ clickable: true }}
                                className={styles.swiperSlideContainer}
                            >
                                {
                                    logoArray.current.map((item, index) => {
                                        return <SwiperSlide className={styles.swiperSlide} key={index}>
                                            <div className={styles.flex}>
                                                {
                                                    item.map((children, childIndex) => {
                                                        return <div key={childIndex}>
                                                            <img className={styles.logoCustomer} src={require(`@/${children}`)} />
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </SwiperSlide>
                                    })
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
