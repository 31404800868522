import * as dd from 'dingtalk-jsapi';

//修改头部标题的 -- 兼容微信、钉钉和浏览器
export const ChangePageTitle = (title) => {
    //判断当前页面是在钉钉、微信还是浏览器中打开
    const ua = navigator.userAgent.toLowerCase()
    let isWeiXin = false,
    isDingTalk = false,
    isApp = false;
    if(ua.match(/DingTalk/i)=="dingtalk"){ //用钉钉打开
        isDingTalk = true
    }else if(ua.match(/MicroMessenger/i)=="micromessenger"){ //用微信打开
        isWeiXin = true
    }else{ //用其他浏览器打开
        isApp = true
    }
    if(isDingTalk){ //钉钉内
        dd.ready(function() {
            dd.biz.navigation.setTitle({
                title: title,
            })
        })
    }else{ //微信或浏览器内
        document.title = title; //普通浏览器用document就可以修改了
        var iframe = document.createElement('iframe');
        /*/ 微信在首次加载页面初始化title后，就再也不监听 document.title
         给页面加上一个内容为空的iframe，随后立即删除这个iframe，这时候会刷新title。 /*/
        iframe.onload = function () {
            setTimeout(function () {
              document.body.removeChild(iframe);
            }, 0);
            document.body.appendChild(iframe);
        }
    }
}