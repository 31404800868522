import React from 'react';
import Header from '@/pages/Header';
import Footer from '@/pages/Footer';
import HeaderMobile from '@/pagesMobile/Header';
import FooterMobile from '@/pagesMobile/Footer';
import { routePc, routerMobile } from '@/Router/router';
import {
    BrowserRouter,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import { ChangePageTitle } from './util';

class RouterConfig extends React.Component{
    render(props){

    let isMobile = false;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        isMobile = true;
        console.log('移动端')
    }
        return (
            <BrowserRouter>
            {isMobile ?  <HeaderMobile /> : <Header />}
                {
                    isMobile ?
                        <Switch>
                            {
                                routerMobile[0].children.map(item => (
                                    <Route
                                        path={item.path}
                                        exact
                                        render={props => {
                                            ChangePageTitle(`${item.name} - 分效科技`)
                                            return (<item.component {...props} />)
                                        }}
                                    />
                                ))
                            }
                            <Redirect to='/home' />
                        </Switch>
                        :
                        <Switch>
                            {
                                routePc[0].children.map(item => (
                                    <Route
                                        path={item.path}
                                        exact
                                        render={props => {
                                            document.title = `${item.name} - 分效科技`
                                            return (<item.component {...props} />)
                                        }}
                                    />
                                ))
                            }
                            <Redirect to='/home' />
                        </Switch>
                }
                {isMobile ? <FooterMobile /> : <Footer />}
            </BrowserRouter>
        )
    }
}
export default RouterConfig;