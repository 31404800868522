import React from 'react';


const Test = () => (
    <div>
      <img  src="http://busmedia-qd-new.obs.cn-north-4.myhuaweicloud.com/pub/material/20240312/02guxiangsheyingH51080X1920_20240312101946876.jpg" alt="" />
    </div>
);

export default Test;
