import React from 'react';
import banner from '@/assets/images/product/bannerMobile.png';
import title1 from '@/assets/images/product/title1.png';
import title2 from '@/assets/images/product/title2.png';
import title3 from '@/assets/images/product/title3.png';
import title4 from '@/assets/images/product/title4.png';
import titleNew1 from '@/assets/images/product/titleNew1.png';
import product_img1 from '@/assets/images/product/product_img1.png';
import product_img5 from '@/assets/images/product/product_img5.png';
import product_img4 from '@/assets/images/product/product_img4.png';
import imgMobileNew from '@/assets/images/product/imgMobileNew.png';
import { productAdvantage, digitalScreen }  from '@/pagesMobile/const';
import styles from './index.module.less';


const Product = () => (
    <div>
        <div className={styles.product}>
            <div className={styles.banner}>
                <img src={banner} alt="" />
                <div className={`${styles.banner_title} animateTopFade`}>
                    <h4>智能出行多媒体营销</h4>
                    <h4>让线下营销更易触达</h4>
                </div>
            </div>
            <div className={styles.panel1}>
                <div className={styles.content}>
                    <h4 className={styles.title}>智能播控系统</h4>
                    <img src={title1} alt="" />
                    <div className={styles.desc}>
                        <p>分效科技联合北京广电移动电视基于广电行业特性结合互联网科技，打造分效智能播控系统，赋能全国广电节目播/广告业务数字化，数据化，智能化业务升级转型。</p>
                    </div>
                    <div className={styles.descImg}>
                        <img src={product_img1} alt="" />
                    </div>
                </div>
                <div className={styles.newBox}>
                    <div className={styles.titleBox}>
                        <div>完整的行业解决方案</div>
                        <img src={titleNew1} alt="" />
                    </div>
                    <div className={styles.contentBox}>
                        通过AIoT+SAAS对线下媒体广告进行数字化改造，让线下媒体投放变得简单高效；开放API接口，实现线下广告线上自助下单；通过物联网终端设备收集终端播放数据、线上线下广告投放数据、和支付宝用户数据深度结合，实现企业主整合营销的多元化营销需求。
                    </div>
                    <div className={styles.newImgBox}>
                        <img src={imgMobileNew} alt="" />
                    </div>
                </div>
            </div>
            <div className={styles.panel2}>
                <div className={styles.container}>
                    <div className={styles.content}>
                        <h4 className={styles.title}>产品优势</h4>
                        <img src={title2} alt="" />
                    </div>
                    <div className={styles.productAdvantage}>
                        {
                            productAdvantage.map(item => (
                                <div className={styles.flex}>
                                    <img src={item.icon} alt={item.title} />
                                    <div className={styles.rightPanel}>
                                        <h4>{item.title}</h4>
                                        <p>{item.desc}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>

            <div className={styles.panel3}>
                <div className={styles.content}>
                    <h4 className={styles.title}>数字屏</h4>
                    <img src={title3} alt="" />
                </div>
                <div className={styles.digitalScreen}>
                    <div className={styles.flex}>
                        {
                            digitalScreen.map(item => (
                                <div className={styles.productCard}>
                                    <h4>{item.screenType}</h4>
                                    <img src={item.icon} alt={item.screenType} />
                                    <p>{item.title}</p>
                                </div> 
                            ))
                        }
                    </div>
                    <div className={styles.full_screen}>
                        <h4>全屏视频/全屏海报</h4>
                        <img src={product_img4}  />
                        <p>21.5寸屏-全屏</p>
                    </div> 
                </div>
            </div>  

            <div className={styles.panel4}>
                <div className={styles.content}>
                    <h4 className={styles.title}>32寸公交智慧屏示例</h4>
                    <img src={title4} alt="32寸公交智慧屏示例" />
                </div>
                <div className={styles.desc}>
                    <p>大尺寸霸占车厢内视野，每车2-4个屏幕终端，8音响布局，让视听传播更完整更清晰。封闭环境内受众注意力聚焦，品牌广告集中曝光有效提升信息触达率。</p>
                </div>
                <div className={styles.descImg}>
                    <img src={product_img5} alt="" />
                </div>
            </div>
        </div>
    </div>
);

export default Product;
