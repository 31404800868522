
import React, { useState, useEffect } from 'react';
import { Button, Table, Modal, Form, Input, Upload, Divider, Empty, message } from 'antd';
import banner from '@/assets/images/joinUs/banner.jpg';
import axios from 'axios';
import styles from './index.module.less';
import { jionUsList } from '@/pages/const';
import { UploadOutlined } from '@ant-design/icons';
import config from '@/config';

const JoinUs = () => {
    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false);
    const [tableList, setTableList] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [recruitId, setRecruitId] = useState('');
    const [jobTypeList, setJobTypeList] = useState([]);
    const [currentIndex, setCurrentIndex] = useState('');

    function getRecruitList (params){
        axios({
            url: `${config.baseAPI}/site/recruit/list`,
            data: params,
            method: 'post'
        }).then(res => {
            const { list } = res.data.data;
            if(res.status == 200){
                list.map(item => {
                    item.key = item.recruitId
                })
                setTableList(list)
            }
        }
        ).catch(
            err => console.error(err)
        )
    }

    function getJobType() {
        axios({
            url: `${config.baseAPI}/site/recruit/job-type-num`,
            data: {},
            method: 'post'
        }).then(res => {
            const { code, data } = res.data;
            if(code == 0){
                const result = data.reduce((res, cur) => {
                      return res + Number(cur.num)
                },0);
                const arr = {
                    jobType: '全部',
                    num: result
                }
                data.unshift(arr)
                setJobTypeList(data)
            }
        }
        ).catch(
            err => console.error(err)
        )
    }

    function filterJob (index, item){
        setCurrentIndex(index);
        getRecruitList({
            page: 1,
            pageSize: 10,
            jobType: item.jobType == '全部' ? undefined : item.jobType
        })
    }

    useEffect(() => {
        getRecruitList({
            page: 1,
            pageSize: 10
        })
        getJobType()
    }, [])

    const columns = [
        { title: '职位名称', dataIndex: 'jobName', key: 'jobName'},
        { title: '职位类型', dataIndex: 'jobType', key: 'jobType' },
        { title: '工作地点', dataIndex: 'jobAddress', key: 'jobAddress' },
        { title: '招聘人数', dataIndex: 'peopleTotal', key: 'peopleTotal'}
    ];

    function apply (recruitId){
        setVisible(true);
        setRecruitId(recruitId)
    }

    function onCancel (){
        setVisible(false);
        form.resetFields();
    }

    function onFinish (values) {
        const formData = new FormData();
        formData.append('mobilePhone', values.mobilePhone);
        formData.append('name', values.name);
        formData.append('recruitId', recruitId);
        formData.append('uploadFile', fileList[0].originFileObj);
        axios({
            url: `${config.baseAPI}/site/recruit/upload`,
            data: formData,
            method: 'post',
        }).then(res => {
            if(res.status == 200){
                message.success('申请成功')
                setVisible(false);
            }
        }
        ).catch(
            err => console.error(err)
        )
    };

    return (
        <div>
            <div className={styles.join}>
                <div className={styles.banner}>
                    <img src={banner} alt="" />
                    <div className={`${styles.banner_title} animateTopFade`}>
                        <h4>分效科技 期待与您的合作</h4>
                    </div>
                </div>
                <div className={styles.panel1}>
                    <div className={styles.content}>
                        {
                            jionUsList.map(item=> (
                                <div>
                                    <img src={item.icon} alt={item.title} />
                                    <h4>{item.title}</h4>
                                    <p>{item.desc}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className={styles.panel2}>
                    <div className={styles.jobTypeContainer}>
                        <div className={styles.jobType}>筛选类别</div>
                        <div>
                            {
                                jobTypeList && jobTypeList?.map((item, index) => {
                                    return (
                                        <span className={styles.jobTypeBox} style={currentIndex == index?  {background: 'rgba(0, 122, 255, 0.2)', color: '#007AFF'} : {}}  onClick={() => filterJob(index,item)}>
                                            <span>{item.jobType}</span>
                                            <span>({item.num})</span>
                                        </span>
                                    )
                                } 
                                )
                            }
                        </div>
                    </div>
                    <Table
                        columns={columns}
                        expandable={{
                            expandedRowRender: record => (
                                <div>
                                    <p style={{ whiteSpace: 'pre', marginBottom: 30 }}>
                                        <p style={{ fontWeight: 'bold', marginBottom: 0 }}>岗位职责:</p>
                                        {record.duty == '暂无' ? '' : record.duty }
                                    </p>
                                    <p style={{ whiteSpace: 'pre', marginBottom: 0 }}>
                                        <p style={{ fontWeight: 'bold', marginBottom: 0 }}>任职要求:</p>
                                        {record.requirement == '暂无' ? '' : record.requirement}
                                    </p>
                                    <Button type='primary' style={{ float: 'right', marginTop: 20 }} onClick={() => apply(record.recruitId)}>申请职位</Button>
                                </div>
                            ),
                        }}
                        locale={{emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='暂无内容' />}}
                        dataSource={tableList}
                    />
                </div>
                <Modal
                    title='申请职位'
                    visible={visible}
                    okText="确定"
                    cancelText="取消"
                    footer={null}
                    onCancel={onCancel}
            >
                <Form
                    form={form}
                    name="basic"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 18 }}
                    onFinish={onFinish}
                    autoComplete="off"
                    >
                    <Form.Item
                        label="姓名"
                        name="name"
                        rules={[{ required: true, message: '请输入姓名' }]}
                    >
                        <Input placeholder='请输入' />
                    </Form.Item>

                    <Form.Item
                        label="手机号"
                        name="mobilePhone"
                        rules={[
                            { required: true, message: '请输入手机号', max: 11 },
                            { max: 11, min: 11, message: '手机号格式不符合' }
                        ]}
                    >
                        <Input placeholder='请输入' />
                    </Form.Item>
                    <Form.Item
                        label="附件"
                        valuePropName="fileList"
                    >
                        <Upload
                            name="fileName"
                            action=""
                            accept={'application/pdf,image/png,image/jpg,image/jpeg'}
                            fileList={fileList}
                            onChange={(info) => {
                                let fileList = [...info.fileList];
                                fileList = fileList.slice(-1);
                                setFileList(fileList)
                            }}
                            beforeUpload={file => {
                                setFileList([...file])
                                  return false;
                            }}
                        >
                            <Button icon={<UploadOutlined />}>上传简历</Button>
                        </Upload>

                        <span style={{color:'rgba(0, 0, 0, 0.45)', fontSize: 12, margin: '8px 0px 20px 0', display: 'block' }}>
                            支持扩展名：支持格式：.jpeg .png .doc .docx .pdf ，单个文件不能超过20MB
                        </span>
                    </Form.Item>
                    <Divider />
                    <Form.Item wrapperCol={{ offset: 16, span: 8 }} style={{ marginBottom: 0 }}>
                        <Button type="primary" htmlType="submit" style={{ marginRight: 20 }}>确认</Button>
                        <Button type="default" onClick={onCancel}>取消</Button>
                    </Form.Item>
                </Form>
            </Modal>
            </div>
        </div>
    )
};

export default JoinUs;
