import about_icon1 from '@/assets/images/about/icon1.png';
import about_icon2 from '@/assets/images/about/icon2.png';
import about_icon3 from '@/assets/images/about/icon3.png';
import product_icon1 from '@/assets/images/product/icon1.png';
import product_icon2 from '@/assets/images/product/icon2.png';
import product_icon3 from '@/assets/images/product/icon3.png';
import product_icon4 from '@/assets/images/product/icon4.png';
import product_img2 from '@/assets/images/product/product_img2.png';
import product_img3 from '@/assets/images/product/product_img3.png';
import product_img4 from '@/assets/images/product/product_img4.png';
import join_icon1 from '@/assets/images/joinUs/icon1.png';
import join_icon2 from '@/assets/images/joinUs/icon2.png';
import join_icon3 from '@/assets/images/joinUs/icon3.png';
import img2 from '@/assets/images/medium/img2.png';
import img3 from '@/assets/images/medium/img3.png';
import medium_icon1 from '@/assets/images/medium/icon1.png';
import medium_icon2 from '@/assets/images/medium/icon2.png';
import medium_icon3 from '@/assets/images/medium/icon3.png';
import video1 from '@/assets/images/medium/video1.png';

// 企业文化
export const enterpriseCulture = [
    {
        icon: about_icon1,
        title: '使命',
        desc: '让线上线下数字营销变得更简单'
    },
    {
        icon: about_icon2,
        title: '愿景',
        desc: '成为公共出行数字营销领跑者'
    },
    {
        icon: about_icon3,
        title: '价值观',
        desc: '客户第一、诚信正直、高效协作、追求结果',
        // desc1: '高效协作、追求结果'
    }
]

// 产品优势
export const productAdvantage = [
    {
        icon: product_icon1,
        title: '多元化内容运营平台',
        desc: '区别于传统户外广告媒体“广告播放机”形式，具备节目运营与制作权限，通过定制化节目/明星产品符合/思域流量运营/本地生活探店等形式，提供又去有料好玩的内容，增强用户和企业主粘性。'
    },
    {
        icon: product_icon2,
        title: '业务精细精准',
        desc: '基于行业和互联网技术，提供全域、区县、线路、LBS商圈等精准化、精细化的业务投放模型，增加业务类型、业务范围。'
    },
    {
        icon: product_icon3,
        title: '节目智能编排',
        desc: '基于AI算法为广电系统下复杂的节目类型（政府+商业）、投放类型（普投+区域+线路+LBS）政商融合场景下提供精准、高效、智能的自动化编排功能。'
    },
    {
        icon: product_icon4,
        title: '公交智慧运营',
        desc: '为公交行业提供车辆数字孪生，数字化报站，线路轨迹分析，拥堵分析，智能线路调度等数字化公共交通运营能力，提升公交运营效率，降低成功。'
    }
]

// 数字屏
export const digitalScreen = [
    {
        icon: product_img2,
        title: '上屏动态视频/下屏静态海报',
        screenType: '32寸屏-分屏',
    },
    {
        icon: product_img3,
        title: '可视频海报组合，版式自由分割',
        screenType: '32寸屏-全屏',
    },
    // {
    //     icon: product_img4,
    //     title: '全屏视频/全屏海报',
    //     width: 253,
    //     height: 158,
    //     screenType: '21.5寸屏-全屏',
    // },
]

// 联系我们
export const cityList = [
    {
        name:'杭州',
        address: '杭州市西湖区紫霞街71号中节能·西溪首座A1-1座',
        longitude: 120.080173,
        latitude:  30.29536,
    },
    {
        name:'上海',
        address: '上海市闵行区宜山路2000号利丰广场2号楼1层',
        longitude: 121.420797,
        latitude:  31.17776,
    },
    {
        name:'合肥',
        address: '合肥市蜀山区龙图路666号安徽广播电视台西区',
        longitude: 117.227924,
        latitude:  31.812524,
    },

    {
        name:'长沙',
        address: '湖南省长沙市雨花区湘府东路989号长沙广播电视台',
        longitude: 112.981383,
        latitude:  28.099958,
    },
    {
        name:'青岛',
        address: '青岛市南区银川西路国际动漫游戏产业园B座102',
        longitude: 120.421617,
        latitude:  36.093444,
    },
]

// 加入我们
export const jionUsList = [
    {
        icon: join_icon1,
        title: '保障福利',
        desc: '五险一金、双休制度、带薪年假'
    },
    {
        icon: join_icon2,
        title: '快乐工作',
        desc: '餐饮补贴、交通补贴、通讯补贴、零食下午茶'
    },
    {
        icon: join_icon3,
        title: '节日关怀',
        desc: '春节、端午、中秋、圣诞等节日礼品'
    },
]

// 媒介资源
export const advantageList = [
    {
        icon: img2,
        title: '多元化内容运营平台',
        desc: '区别于传统户外广告媒体“广告播放机”形式，具备节目运营与制作权限，通过定制化节目/明星产品孵化/私流域流量运营/本地生活探店等形式，提供又去有料好玩的内容，增强用户和企业主粘性。'
    },
    {
        icon: img3,
        title: '技术领先优势',
        desc: '深度服务全国各地广电进行智能化数字屏幕升级改造，搭建智能投播平台、LBS系统，为客户提供精准、真实、安全、数字化的营销解决方案，公共出行数字化营销服务平台领跑者。'
    },
]

export const diverseContentList = [
    {
        icon: medium_icon1,
        title: '节目植入',
        desc: '在频道内固定节目中软性植入品牌内容,主持人介绍口播，增加曝光同时使受众在观看时潜移默化受到影响。',
        desc1: '适合餐饮、快消等大众消费品牌的信息传达'
    },
    {
        icon: medium_icon2,
        title: '定制专题',
        desc: '专业媒体团队为品牌独家定制 3-5分钟左右的专题栏目，讲透讲细产品优势，受众全面沉浸在品牌信息中。',
        desc1: '适合地产、APP等专业性强，内容丰富的品牌介绍'
    },
    {
        icon: medium_icon3,
        title: '咨询互动',
        desc: '通过屏幕上的小游戏或互动,增加受众的品牌参与，留下深刻印象，形成良好的品牌反馈。',
        desc1: '适合有线上活动或希望做事件营销的品牌使用'
    },
]

// 部分节目展示
export const programVideoList = [
    {
        key:1,
        url:'https://yypt.obs.cn-east-3.myhuaweicloud.com/pub/xuewei6.mp4',
        title:'北广传媒移动电视 — 下一站寻味',
        desc:'一档专为北广公交移动电视频道打造的高质量美食短视频节目。依托 北京二十…',
        poster: video1,
    },
    {
        key:2,
        url: 'https://yypt.obs.cn-east-3.myhuaweicloud.com/pub/WeChat_20211220220046.mp4',
        title:'安徽广播公交移动 —— 下一站探奇',
        desc:'一档专为安徽广播公交移动电视频道打造的高质量探店类短视频节目。 依托合肥本地生活的丰富资源，为合肥受众带来非凡的视觉饕餮盛宴'
    },
    {
        key:3,
        url: 'https://yypt.obs.cn-east-3.myhuaweicloud.com/pub/ehuoguo.mp4',
        title:'安徽广播公交移动 —— 下一站探奇',
        desc:'一档专为安徽广播公交移动电视频道打造的高质量探店类短视频节目。 依托合肥本地生活的丰富资源，为合肥受众带来非凡的视觉饕餮盛宴'
    },
]