import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Toast } from 'antd-mobile';
import { Upload } from 'antd';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import styles from './index.module.less';
import config from '@/config';
import addIcon from '@/assets/images/joinUs/add_icon.png'

const Apply = (props) => {
    const [applyInfo, setApplyInfo] = useState([]);
    const [fileList, setFileList] = useState([]);


    useEffect(() => {
        const { state } = props.location;
        if (state.applyInfo) {
            setApplyInfo(state.applyInfo)
        }
    }, [])

    function onFinish(values) {
        const formData = new FormData();
        formData.append('mobilePhone', values.mobilePhone);
        formData.append('name', values.name);
        formData.append('recruitId', applyInfo.recruitId);
        console.log(formData, 'formData')
        if(fileList.length > 0){
            formData.append('uploadFile', fileList[0].originFileObj);
        }
        if(!values.name && !values.mobilePhone || fileList.length == 0){
            Toast.show({
                content: '请填写信息',
            })
            return false
        }
        Toast.show({
            icon: 'loading',
            content: '加载中…',
        })
        axios({
            url: `${config.baseAPI}/site/recruit/upload`,
            data: formData,
            method: 'post',
        }).then(res => {
            if (res.data.code == 0) {
                Toast.show({
                    icon: 'success',
                    content: '提交成功',
                })
                props.history.push('/join')
            } else {
                Toast.show({
                    content: res.data.message,
                })
            }
        }).catch(
            err => console.error(err)
        )
    };

    return (
        <div className={styles.apply}>
            <div className={styles.apply1}>
                <p>{applyInfo.jobName}</p>
                <p className={styles.panelDesc}>{`${applyInfo.jobType}.${applyInfo.jobAddress}.${applyInfo.peopleTotal}人`}</p>
            </div>
            <div className={styles.baseinfo}>
                <span className={styles.icon}></span>
                <span className={styles.title}>基础信息</span>
            </div>
            <Form
                className={styles.form}
                layout='horizontal'
                onFinish={onFinish}
                footer={
                    <Button block type='submit' color='primary' size='large'>
                        提交
                    </Button>
                }
            >
                <Form.Item
                    name='name'
                    label='姓名'
                >
                    <Input onChange={console.log} placeholder='请输入' />
                </Form.Item>
                <Form.Item 
                    name='mobilePhone' 
                    label='手机号'
                    rules={[
                        { message: '请输入手机号', max: 11 },
                        { max: 11, min: 11, message: '手机号格式不符合' }
                    ]}
                >
                    <Input onChange={console.log} placeholder='请输入' />
                </Form.Item>
                <div className={styles.baseinfo}>
                    <span className={styles.icon}></span>
                    <span className={styles.title}>附件简历</span>
                </div>
                <div className={styles.uploadBox}>
                    <div className={styles.upload}>
                        <Upload
                            name="fileName"
                            action=""
                            accept={'application/pdf,image/png,image/jpg,image/jpeg'}
                            fileList={fileList}
                            onChange={(info) => {
                                let fileList = [...info.fileList];
                                fileList = fileList.slice(-1);
                                setFileList(fileList)
                            }}
                            beforeUpload={file => {
                                setFileList([...file])
                                return false;
                            }}
                        >
                            <img src={addIcon} alt="" />
                            <p className={styles.title}>上传附件简历</p>
                            <p className={styles.desc}>支持格式：.jpeg .png .doc .docx .pdf ，单个文件不能超过20MB</p>
                        </Upload>
                    </div>
                </div>
            </Form>
        </div>
    )
};

export default withRouter(Apply);
