
import React, { useState, useEffect } from 'react';
import { Link, withRouter } from "react-router-dom";
import { List, Empty } from 'antd';
import banner from '@/assets/images/news.jpg';
import axios from 'axios';
import styles from './index.module.less';
import config from '@/config';


const News = (props) => {
    const [newList, setNewsList] = useState([]);
    const [total, setTotal] =  useState(0);

    function getNewsList (params){
        axios({
            url: `${config.baseAPI}/site/news/list`,
            data: params,
            method: 'post'
        }).then(res => {
            if(res.status == 200){
                const { items, itemCount } = res?.data.data.itemList || {};
                setTotal(itemCount);
                setNewsList(items);
            }
        }
        ).catch(
            err => console.error(err)
        )
    }

    useEffect(() => {
        getNewsList({
            page: 1,
            pageSize: 5,
            state: 1
        });
    }, [])

    function toDetail (item){
        if(item.type == 1){
            props.history.push({
                pathname: `/news/detail/${item.newsId}`,
                state:{
                    newsId: item.newsId
                }
            })
        } else {
            window.open(item.content)
        }
    }

    return (
        <div>
            <div className={styles.news}>
                <div className={styles.banner}>
                    <img src={banner} alt="" />
                    <div className={`${styles.banner_title} animateTopFade`}>
                        <h4>智能出行多媒体营销 让线下营销更易触达</h4>
                    </div>
                </div>
                <div className={styles.content}>
                    <List
                        itemLayout="vertical"
                        size="large"
                        pagination={{
                            onChange: page => {
                                getNewsList({ page, pageSize: 5,state: 1 })
                            },
                            total: total,
                            pageSize: 5,
                        }}
                        locale={{emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='暂无内容' />}}
                        dataSource={newList}
                        renderItem={(item, index) => (
                            <List.Item key={index}>
                                <Link onClick={() => toDetail(item)} >
                                    <div className={styles.newsList}>
                                        <div className={styles.newsImg}>
                                            <img
                                                alt={item.title}
                                                src={item.imageAddress}
                                            />
                                        </div>
                                        <div className={styles.newsContent}>
                                            <div className={styles.title}>{item.title}</div>
                                            <p className={styles.summary}>{item.summary}</p>
                                            <p className={styles.createdTime}>{item.createdTime}</p>
                                        </div>
                                    </div>
                                </Link>
                            </List.Item>
                        )}
                    />
                </div>
            </div>
        </div>
    )
};

export default withRouter(News);
