
import React, { useState, useEffect,useRef } from 'react';
import { Link, withRouter } from "react-router-dom";
import { InfiniteScroll } from 'antd-mobile'
import banner from '@/assets/images/bannerMobile.png';
import axios from 'axios';
import styles from './index.module.less';
import config from '@/config';


const News = (props) => {
    const [newList, setNewsList] = useState([]);
    const [hasMore, setHasMore] = useState(true)
    const pageRef = useRef(1);

    async function loadMore() {
        pageRef.current++;
        const param = {
            page: pageRef.current,
            pageSize: 5,
            state: 1
        }
       getNewsList(param)
    }

    function getNewsList (params){
        axios({
            url: `${config.baseAPI}/site/news/list`,
            data: params,
            method: 'post'
        }).then(res => {
            if(res.status == 200){
                const { items, page, totalPage } = res?.data.data.itemList || {};
                setNewsList(val => [...val, ...items])
                if(page !=  totalPage){
                    setHasMore(true)
                } else {
                    setHasMore(false)
                }
            }
        }
        ).catch(
            err => console.error(err)
        )
    }

    useEffect(() => {
        getNewsList({
            page: 1,
            pageSize: 5,
            state: 1
        });
    }, [])

    function toDetail (item){
        props.history.push({
            pathname: `/news/detail/${item.newsId}`,
            state:{
                newsId: item.newsId
            }
        })
    }

    return (
        <div>
            <div className={styles.news}>
                <div className={styles.banner}>
                    <img src={banner} alt="" />
                    <div className={`${styles.banner_title} animateTopFade`}>
                        <h4>智能出行多媒体营销</h4>
                        <h4>让线下营销更易触达</h4>
                    </div>
                </div>
                <div className={styles.content}>
                <div className={styles.cardBox}>
                    { newList.map((item, index) => (
                        <div key={index} className={styles.card}>
                            <Link onClick={() => toDetail(item)} >
                                    <div className={styles.newsList}>
                                        <div className={styles.newsImg}>
                                            <img
                                                alt={item.title}
                                                src={item.imageAddress}
                                            />
                                        </div>
                                        <div className={styles.newsContent}>
                                            <div className={styles.title}>{item.title}</div>
                                            <p className={styles.createdTime}>{item.createdTime}</p>
                                        </div>
                                    </div>
                                </Link>
                        </div>
                    ))}
                </div>
                <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
                </div>
            </div>
        </div>
    )
};

export default withRouter(News);
