import React, { useEffect, useState } from 'react';
import { InfiniteScroll, List } from 'antd-mobile'
import banner from '@/assets/images/cooperate/bannerMobile.png';
import title1 from '@/assets/images/cooperate/title1.png';
import styles from './index.module.less';
import axios from 'axios';
import config from '@/config';


const Cooperation = () => {
    const [casesList, setCasesList] = useState([]);
    // const [hasMore, setHasMore] = useState(true)
    const [ total, setTotal] = useState(1);
    
    function getCasesList (params){
        axios({
            url: `${config.baseAPI}/site/cooperation/cases/list`,
            data: params,
            method: 'post'
        }).then(res => {
            if(res.status == 200){
                const { items, page, totalPage } = res.data.data.list
                setCasesList(val => [...val, ...items])
                setTotal(totalPage)
            }
        }
        ).catch(
            err => console.error(err)
        )
    }
    let pageNum = 0
    async function loadMore() {
        
        if(pageNum == total) {
            console.log('1111')
            return false
        }
        pageNum ++
        const param = {
            page: pageNum,
            pageSize: 8,
        }
        getCasesList(param)
    }

    useEffect(()=> {
        getCasesList({
            page: total,
            pageSize: 8
        })
    },[total])


    return(
    <div className={styles.contact}>
        <div className={styles.banner}>
            <img src={banner} alt="" />
            <div className={`${styles.banner_title} animateTopFade`}>
                <h4>为线下营销产业链带来新革命</h4>
            </div>
        </div>
        <div className={styles.panel1}>
            <div className={styles.content}>
                <h4>部分合作案例</h4>
                <img src={title1} alt="" className={styles.title_img} />
                <div className={styles.cooperationCase}>
                        { casesList.map((item, index) => (
                                <div className={ index == casesList.length-1 ? styles.last: styles.box}>
                                    <div className={styles.bgTemplate}>
                                        <img src={item.imageAddress} alt="" />
                                    </div>
                                    <h4 className={styles.templateTitle}>{item.name}</h4>
                                </div>
                        ))}
                    {/* <InfiniteScroll loadMore={loadMore} hasMore={hasMore}  /> */}
                </div>
            </div>
        </div>
    </div>
)};

export default   Cooperation;
