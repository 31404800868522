import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { routePc, routerMobile } from '@/Router/router';
import logo from '@/assets/images/logo_empty.png';
import styles from './index.module.less';

const Header = (props) => {
    const [hideMenu,setHideMenu] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll",function (e){
          const scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
          if(scrollTop > 200){
            setHideMenu(true)
          } else {
            setHideMenu(false)
          }
        });
      }, [])

    return (
    <div className={styles.header} style={{ background: hideMenu ?  '#272727': '' }}>
        <div className={styles.content}>
            <img src={logo} alt="" />
            {
                routePc[0].children.map(item => {
                  if(!item.hide){
                    return (
                      <li className={styles.li} >
                          <Link to={item.path} className={ props.location.pathname == item.path ? styles.active : styles.default}> {item.name} </Link>
                      </li>
                    )
                  }
                })
            }
        </div>
    </div>
)};

export default withRouter(Header);
