import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { routerMobile } from '@/Router/router';
import logo from '@/assets/images/logo_empty.png';
import navigation from '@/assets/navigation.png'
import styles from './index.module.less';

const HeaderMobile = (props) => {
    const [hideMenu,setHideMenu] = useState(false);
    const [showNav,setShowNav] = useState(false);
    const [header, setHeader] = useState(true);

    useEffect(() => {
        window.addEventListener("scroll",function (e){
          const scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
          if(scrollTop > 200){
            setHideMenu(true)
            setShowNav(false)
          } else {
            setHideMenu(false)
          }
        });
        if(props.location.pathname == '/join_add'){
          setHeader(false)
        } else {
          setHeader(true)
        }
      }, [props.location.pathname])

      function showMenuList (e){
        e.stopPropagation(); 
        setShowNav(!showNav)
      }

      function hideMenuList (){
        setShowNav(false)
      }

    return (
    <div className={styles.header} style={{ background: (hideMenu || showNav) ?  '#333333': '', display: header ? 'block' : 'none' }}>
        <div className={styles.content}>
            <img src={logo} alt="" className={styles.logo} />
            <div className={styles.navigation} onClick={showMenuList}>
              <img src={navigation} alt=""/>
            </div>
        </div>
        <div className={styles.menu} style={{ display: showNav ?  'block' : 'none' }}>
            {
                routerMobile[0].children.map(item => {
                  if(!item.hide){
                    return (
                      <li className={styles.li} onClick={hideMenuList}>
                          <Link to={item.path} className={ props.location.pathname == item.path ? styles.active : styles.default}> {item.name} </Link>
                      </li>
                    )
                  }
                })
            }
        </div>
    </div>
)};

export default withRouter(HeaderMobile);
